<template>
  <div class="footer mt-24 bg-white">
    <div class="footer-top bg-primary text-white py-4 px-24">
      <div class="text-center flex flex-wrap items-center justify-center">
        <div class="mr-4">
          Follow us on:
        </div>
        <ul class="flex gap-2">
          <li>
            <InstagramIcon class="w-6" />
          </li>
          <li>
            <FacebookIcon class="w-6" />
          </li>
          <li>
            <XIcon class="w-6" />
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-bottom pt-14 pb-8">
      <div class="px-12 lg:px-16 xl:px-24 flex flex-wrap items-start">
        <div class="w-full md:w-2/5 mb-12 md:pr-24">
          <Logo class="w-40 mb-8" />
          <NewsletterSignupForm />
        </div>
        <div class="w-full mb-12 md:w-3/12">
          <PageMenuLayout menu-location="PRIMARY" menu-classes="columns-2" item-classes="mb-4" />
        </div>
        <div class="w-full md:w-1/3 mb-12 gap-3 flex items-center md:justify-end">
          <EnvelopeIcon class="w-7" />
          <NuxtLink to="mailto:hello@happiplans.com" class="font-medium">hello@happiplans.com</NuxtLink>
        </div>
      </div>
    </div>
    <div class="footer-legal py-2 lg:py-3 border-t border-grey">
      <div class="px-4 lg:px-16 xl:px-24 flex flex-wrap items-center justify-between">
        <div class="md:w-1/3">
          <span class="text-xs">&copy; Happiplans {{ getCurrentYear() }}</span>
        </div>
        <div class="md:w-2/3 gap-3 flex flex-wrap items-center justify-end">
          <PageMenuLayout menu-location="LEGAL" menu-classes="text-xs flex gap-4 items-center" />
          <span class="text-xs ml-2">Designed &amp; developed by: <NuxtLink to="https://realbranding.agency"
                                                                            class="underline">Real Branding&copy;</NuxtLink>
          </span>
        </div>
      </div>
    </div>
  </div>
  <MobileNavigation v-if="screenState.getScreen.isMobile && screenState.getScreen.isHamburgerMenuOpen" />
</template>
<script setup lang="ts">
import PageMenuLayout from "~/layouts/PageMenuLayout.vue";
import EnvelopeIcon from "~/components/global/Icons/EnvelopeIcon.vue";
import InstagramIcon from "~/components/global/Icons/InstagramIcon.vue";
import FacebookIcon from "~/components/global/Icons/FacebookIcon.vue";
import XIcon from "~/components/global/Icons/XIcon.vue";
import MobileNavigation from "~/components/global/Navigation/MobileNavigation.vue";
import { useScreenState } from "~/stores/screen";

const screenState = useScreenState();

const getCurrentYear = () => {
  let date = new Date();
  return date.getFullYear();
};

const maybeCloseMobileAfterRouteChange = () => {
  if (screenState.getScreen.isHamburgerMenuOpen) {
    screenState.toggleMenu();
  }
};

onBeforeRouteUpdate(() => {
  maybeCloseMobileAfterRouteChange();
});

onBeforeRouteLeave(() => {
  maybeCloseMobileAfterRouteChange();
});
</script>
